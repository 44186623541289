const formatDateForURL = (date) => {
  const mm = String(date?.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so add 1
  const dd = String(date?.getDate()).padStart(2, '0');
  const yyyy = date?.getFullYear();

  return `${mm}/${dd}/${yyyy}`;

  // URL-encode the date string
  // return encodeURIComponent(formattedDate);
}

export const useFacilitySearch = defineStore('facilitySearch', {
  state: () => ({
    facility: {},
    checkInDate: formatDateForURL(new Date()),
    checkOutDate: '',
    results: [],
    searchParams: {},
    loading: false,
  }),
  actions: {
    setFacility(facility) {
      this.facility = facility;
    },
    setCheckInDate(checkInDate) {
      this.checkInDate = formatDateForURL(checkInDate);
    },
    setCheckOutDate(checkOutDate) {
      this.checkOutDate = formatDateForURL(checkOutDate);
    },
    setSearchResults(results) {
      this.results = results;
    },
    setParams(params) {
      this.searchParams = params;
    },
    setLoading(loading) {
      this.loading = loading;
    },
    resetFacility() {
      this.facility = {};
    }
  },
  getters: {
    getCheckInDate: (state) => decodeURIComponent(state.checkInDate),
    getCheckOutDate: (state) => decodeURIComponent(state.checkOutDate),
    getSearchQuery: (state) => {
      return (checkIn, checkOut) => {
        return `/search?facility=${state.facility.id || 'all'}&checkinDate=${checkIn}${checkOut ? `&checkoutDate=${checkOut}` : ''}`
      }
    }
  }
})
